<script>
import SelectCompany from "@/components/custom/SelectCompany.vue";
import "@vueform/multiselect/themes/default.css";
import Multiselect from "@vueform/multiselect";
import axios from "axios";
import Swal from "sweetalert2";
import store from "@/state/store";
import {saveAs} from 'file-saver';
import ExcelJS from 'exceljs';
import moment from "moment";

import numberToWordsRu from "number-to-words-ru"

export default {
  name: "AvrLayout",
  components: {
    SelectCompany,
    Multiselect
  },
  props: {
    order_type: {
      type: String,
      required: true,
      default: () => 'container_order'
    }
  },
  data() {
    return {
      form: {
        customer: null,
        performer: null,
        contract: null,
        inn: '',
        lot_number: null,
        orders: [],

        avr_number: '',
        avr_name: '',
        date: new Date().toISOString().substr(0, 10),
        service_name: '',
        director_name: '',
        total_agreed_rate_in_words: 'Ноль',

        start_date: null,
        expire_date: null,

        details: [],
        is_loading_details: false,
        is_submitting: false
      },
      contractSelect: {
        class: "form-control border-0",
        options: [],
        placeholder: "Select contract",
        object: true,
        has_loaded_contracts: false,
      },
      lotNumberSelect: {
        class: "form-control",
        options: [],
        placeholder: "Select a lot number to import orders by",
        object: true,
        searchable: true,
      },
      ordersSelect: {
        class: "form-control py-1 ps-1",
        options: [],
        placeholder: "Select orders",
        object: true,
        mode: 'tags'
      }
    }
  },
  computed: {
    totalQuantity() {
      return this.form.orders.reduce((acc, order) => acc + order.quantity, 0)
    },
    totalAgreedRate() {
      return this.form.orders.reduce((acc, order) => acc + parseFloat(order.total_agreed_rate || 0), 0)
    },
    allFieldsFilled() {
      return this.form.customer !== null && this.form.performer !== null && this.form.contract !== null
          && this.form.orders.length > 0 && this.form.service_name.trim().length > 0 &&
          this.form.avr_number.trim().length > 0 && this.form.total_agreed_rate_in_words.trim().length > 0 && this.form.director_name.trim().length > 0
    },
    currentUser() {
      return store.state.user
    },
    possible_avr_name_options() {
      let options = []

      if (this.form.customer) {

        options.push({
          label: this.form.customer.label.split(' ')[0]
        })

        if (this.form.date) {
          let date = moment(this.form.date, 'YYYY-MM-DD');
          options.push({
            label: this.form.customer.label.split(' ')[0] + '-' + date.format('MMMM').toUpperCase()
          })
        } else {
          let date = moment(new Date(), 'DD/MM/YYYY');
          options.push({
            label: this.form.customer.label.split('')[0] + '-' + date.format('MMMM').toUpperCase()
          })
        }

        if (this.form.avr_number) {
          options.push({
            label: this.form.customer.label.split(' ')[0] + '-' + this.form.avr_number.trim()
          })
        }
      }

      return options
    }
  },
  methods: {
    clearCustomer() {
      this.form.customer = null;
      this.contractSelect.options = [];
    },
    clearContract() {
      this.form.contract = null;
      this.contractSelect.options = [];
      this.contractSelect.has_loaded_contracts = false;
    },
    clearLotNumber() {
      this.form.lot_number = null;
      this.lotNumberSelect.options = [];
    },
    clearOrders() {
      this.form.orders = [];
      this.ordersSelect.options = [];
    },

    onCustomerSelected(customer, manually_selected) {
      if (customer) {
        this.form.customer = customer;
        if (manually_selected) {
          this.form.director_name = this.form.customer.director_name || ''
        }
        this.getContractsByCustomer();
      } else {
        this.clearCustomer();
        this.clearContract();
      }
    },
    onPerformerSelected(performer) {
      this.form.performer = performer ? performer : null;
    },
    autoSelectContract(contracts) {
      if ((contracts || []).length === 1) {
        this.form.contract = contracts[0];
      }
    },
    setDefaultPerformer() {
      if (this.currentUser.company && this.currentUser.company.id) {
        this.form.performer = {
          value: this.currentUser.company.id,
          label: this.currentUser.company.name,
          address: this.currentUser.company.address || ''
        }
      }
    },
    downloadFile(path) {
      if (!path) return;
      let url = process.env.VUE_APP_ORDER_URL + path;
      fetch(url)
          .then((resp) => resp.blob())
          .then((blobobject) => {
            const blob = window.URL.createObjectURL(blobobject);
            const anchor = document.createElement("a");
            anchor.style.display = "none";
            anchor.href = blob;
            anchor.download = url.toString().split("/")[
            url.toString().split("/").length - 1
                ];
            document.body.appendChild(anchor);
            anchor.click();
            window.URL.revokeObjectURL(blob);
          })
          .catch(() => alert("An error in downloading the file sorry"));
    },
    async getContractsByCustomer() {
      if (this.form.customer && this.form.customer.slug) {
        try {
          let response = await axios.get(`/contract/${this.form.customer.slug}/by_company/`);
          this.contractSelect.options = (response.data.results || []).map((contract) => {
            return {
              value: contract.id,
              label: contract.name,
              slug: contract.slug,
              start_date: contract.start_date,
              expire_date: contract.expire_date,
            }
          });
          this.contractSelect.has_loaded_contracts = true;
          this.autoSelectContract(this.contractSelect.options);
        } catch {
          this.contractSelect.has_loaded_contracts = true;
          this.contractSelect.options = []
        }
      } else {
        alert('Failed to load contracts: Select a customer first !');
      }
    },
    async getLotNumbersByCompanyIdAndContract() {
      if (
          (this.form.customer && this.form.customer.value) &&
          (this.form.contract && this.form.contract.value)
      ) {
        try {
          let response = await axios.get(`/contract/act_performed_work/get_lot_number/${this.form.customer.value}/`, {
            params: {
              order_type: this.order_type,
              contract_id: this.form.contract.value
            }
          })
          this.lotNumberSelect.options = response.data.map(lot_number => {
            lot_number.orders.forEach(order => {
              this.ordersSelect.options.push({
                value: order.id,
                label: order.order_number,
                quantity: order.quantity || 0,
                total_agreed_rate: order.total_agreed_rate || 0,
              })
            })
            return {
              value: lot_number.lot_number,
              label: lot_number.lot_number,
            }
          })
        } catch {
          this.lotNumberSelect.options = []
        }
      } else {
        alert('Failed to load lot numbers: Select a customer first !');
      }
    },
    async autoSelectOrdersByLotNumber() {
      if (this.form.lot_number) {
        try {
          let response = await axios.get(`/contract/act_performed_work/get_finance_info/${this.form.customer.value}/`, {
            params: {
              lot_number: this.form.lot_number.value,
              order_type: this.order_type,
              contract_id: this.form.contract.value
            }
          })
          this.form.orders = (response.data || []).map(order => {
            return {
              value: order.id,
              label: order.order_number,
              quantity: order.quantity || 0,
              total_agreed_rate: order.total_agreed_rate || 0,
            }
          })
        } catch {
          this.clearOrders()
        }
      } else {
        alert('Failed to load orders: Select a lot number first !');
      }
    },
    async getAvrDetails() {
      const cancelTokenSource = axios.CancelToken.source();

      if (this.cancelDetailsSource) {
        this.cancelDetailsSource.cancel('Request canceled due to new request.');
      }

      this.cancelDetailsSource = cancelTokenSource;

      this.form.is_loading_details = true;

      try {
        let response = await axios.get(`/contract/act_performed_work/get_order_detail/`, {
          params: {
            order_type: this.order_type,
            order_numbers: this.form.orders.map(order => order.label).join(',')
          },
          cancelToken: cancelTokenSource.token
        });

        this.form.details = response.data || [];
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('Request canceled:', error.message);
        } else {
          console.error('Error in getAvrDetails:', error);
          this.form.details = [];
        }
      } finally {
        this.form.is_loading_details = false;
      }
    },
    async getAvrDetailsByAvrId() {
      try {
        let response = await axios.get(`/contract/act_performed_work/get_order_detail/${this.$route.params.avr_id}/`)
        this.onCustomerSelected({
          value: response.data.contract.company.id,
          label: response.data.contract.company.name,
          slug: response.data.contract.company.slug,
          address: response.data.contract.company.address,
          director_name: response.data.contract.company.director_name || '',
        }, false)
        this.form.performer = {
          value: response.data.executor.id,
          label: response.data.executor.name,
          address: response.data.executor.address
        }
        this.form.orders = (response.data.orders || []).map(order => {
          return {
            value: order.id,
            label: order.order_number,
            quantity: order.quantity || 0,
            total_agreed_rate: order.total_agreed_rate || 0,
          }
        })
        this.form.contract = {
          value: response.data.contract.id,
          label: response.data.contract.name,
          slug: response.data.contract.slug,
          start_date: response.data.contract.start_date,
          expire_date: response.data.contract.expire_date,
        }
        this.form.inn = response.data.tin || ''
        this.form.avr_number = response.data.number || ''
        this.form.service_name = response.data.service_name || ''
        this.form.total_agreed_rate_in_words = response.data.price_in_word || ''
        this.form.director_name = response.data.customer_director_name || ''
        this.form.avr_name = response.data.name || ''

        this.form.start_date = response.data.date_from || null
        this.form.expire_date = response.data.date_to || null
      } catch {
        await Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
        })
      }
    },
    async submitForm() {
      if (this.$route.params.avr_id) {
        return this.updateAvr();
      } else {
        return this.createAvr();
      }
    },
    async createAvr() {
      if (!this.allFieldsFilled) {
        return alert('Fill all the fields first !');
      }
      if (this.form.is_submitting) {
        return alert('Please wait, form is submitting !');
      }
      try {
        this.form.is_submitting = true
        let response = await axios.post(`/contract/act_performed_work/create/`, {
          contract_id: this.form.contract.value,
          name: this.form.avr_name.trim(),
          number: this.form.avr_number.trim(),
          date: this.form.date,
          service_name: this.form.service_name.trim(),
          customer_director_name: this.form.director_name.trim(),
          order_numbers: this.form.orders.map(order => order.label),
          tin: this.form.inn.trim(),
          price_in_word: this.form.total_agreed_rate_in_words.trim(),
          executor_id: this.form.performer.value,
          order_type: this.order_type,
          date_from: this.form.start_date,
          date_to: this.form.expire_date
        })
        this.downloadFile(response.data.file);
        this.form.is_submitting = false
        await Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'AVR created successfully!',
        })
      } catch {
        this.form.is_submitting = false
        await Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
        })
      }
    },
    async updateAvr() {
      if (!this.allFieldsFilled) {
        return alert('Fill all the fields first !');
      }
      if (this.form.is_submitting) {
        return alert('Please wait, form is submitting !');
      }
      try {
        this.form.is_submitting = true
        let response = await axios.put(`/contract/act_performed_work/update/${this.$route.params.avr_id}/`, {
          contract_id: this.form.contract.value,
          name: this.form.avr_name.trim(),
          number: this.form.avr_number.trim(),
          date: this.form.date,
          service_name: this.form.service_name.trim(),
          customer_director_name: this.form.director_name.trim(),
          order_numbers: this.form.orders.map(order => order.label),
          tin: this.form.inn.trim(),
          price_in_word: this.form.total_agreed_rate_in_words.trim(),
          executor_id: this.form.performer.value,
          order_type: this.order_type,
          date_from: this.form.start_date,
          date_to: this.form.expire_date
        })
        this.downloadFile(response.data.file);
        this.form.is_submitting = false
        await Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'AVR updated successfully!',
        })
      } catch {
        this.form.is_submitting = false
        await Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
        })
      }
    },
    async avrDeleteConfirmation() {
      await Swal.fire({
        icon: "error",
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        confirmButtonColor: "#f06548",
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteAvr()
        }
      });
    },
    async deleteAvr() {
      try {
        await axios.delete(`/contract/act_performed_work/delete/${this.$route.params.avr_id}/`)
        await Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'AVR deleted successfully!',
        })
        await this.$router.push({name: 'avr_list'})
      } catch {
        await Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
        })
      }
    },


    async exportToExcel() {
      try {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Orders');

        this.form.details.forEach((order, order_index) => {
          worksheet.addRow([]);

          const titleRow = worksheet.addRow([`Order Number: ${order.order_number}`]);
          titleRow.font = {bold: true};
          worksheet.mergeCells(`A${titleRow.number}:P${titleRow.number}`);

          this.generateHeaderRow(worksheet);

          if (this.order_type === 'container_order') {
            this.addContainerOrderRows(worksheet, order, order_index);
          } else if (this.order_type === 'wagon_order') {
            this.addWagonOrderRows(worksheet, order, order_index);
          } else if (this.order_type === 'wagon_empty_order') {
            this.addWagonEmptyOrderRows(worksheet, order, order_index);
          }

          worksheet.addRow([]); // Add an empty row for spacing between orders
        });

        const buffer = await workbook.xlsx.writeBuffer();
        saveAs(new Blob([buffer]), `${this.slugify(this.form.avr_name)}_${moment().format('YYYY.MM.DD')}.xlsx`);
      } catch (error) {
        console.error("Error exporting to Excel:", error);
        alert("Something went wrong");
      }
    },
    generateHeaderRow(worksheet) {
      let headers;
      if (this.order_type === 'container_order') {
        headers = [
          '№',
          '№',
          'Дата отправления',
          'Станция отправления',
          'Станция назначения',
          'Груз',
          'ЕТСНГ/ГНГ',
          'Номер контейнера',
          'Номер накладной',
          'Код/подкод',
          'Территория',
          'Тип вагона/контейнера',
          'Ставка/ доллар',
          'Дополнительные расходы',
          'Итого к оплате'
        ];
      } else if (this.order_type === 'wagon_order') {
        headers = [
          '№',
          '№',
          'Дата отправления',
          'Станция отправления',
          'Станция назначения',
          'Груз',
          'ЕТСНГ/ГНГ',
          'Вес',
          'Номер вагона',
          'Номер накладной',
          'Код/подкод',
          'Территория',
          'Тип вагона/контейнера',
          'Ставка/ доллар',
          'Дополнительные расходы',
          'Итого к оплате'
        ];
      } else if (this.order_type === 'wagon_empty_order') {
        headers = [
          '№',
          '№',
          'Дата отправления',
          'Станция отправления',
          'Станция назначения',
          'Вес',
          'Номер вагона',
          'Номер накладной',
          'Код/подкод',
          'Территория',
          'Тип вагона/контейнера',
          'Ставка/ доллар',
          'Дополнительные расходы',
          'Итого к оплате'
        ];
      }

      const headerRow = worksheet.addRow(headers);
      headerRow.eachCell((cell) => {
        cell.font = {bold: true};
        cell.alignment = {vertical: 'middle', horizontal: 'center'};
        cell.border = {
          top: {style: 'thin'},
          left: {style: 'thin'},
          bottom: {style: 'thin'},
          right: {style: 'thin'}
        };
      });
    },
    addContainerOrderRows(worksheet, order, order_index) {
      order.containers.forEach((container, index) => {
        const codes = container.codes || [];

        if (codes.length === 0) {
          this.addContainerRow(worksheet, order, container, index, order_index, true);
        } else {
          codes.forEach((code, codeIndex) => {
            this.addContainerRow(worksheet, order, container, index, order_index, codeIndex === 0, code, codeIndex === codes.length - 1);
          });
        }
      });
    },
    addWagonOrderRows(worksheet, order, order_index) {
      order.wagons.forEach((wagon, index) => {
        const codes = wagon.codes || [];

        if (codes.length === 0) {
          this.addWagonRow(worksheet, order, wagon, index, order_index, true);
        } else {
          codes.forEach((code, codeIndex) => {
            this.addWagonRow(worksheet, order, wagon, index, order_index, codeIndex === 0, code, codeIndex === codes.length - 1);
          });
        }
      });
    },
    addWagonEmptyOrderRows(worksheet, order, order_index) {
      order.empty_wagons.forEach((wagon, index) => {
        const codes = wagon.codes || [];

        if (codes.length === 0) {
          this.addWagonEmptyRow(worksheet, order, wagon, index, order_index, true);
        } else {
          codes.forEach((code, codeIndex) => {
            this.addWagonEmptyRow(worksheet, order, wagon, index, order_index, codeIndex === 0, code, codeIndex === codes.length - 1);
          });
        }
      });
    },
    addContainerRow(worksheet, order, container, index, order_index, isFirstRow, code, isLastCodeRow) {
      const row = worksheet.addRow([
        isFirstRow ? (order_index === 0 ? index + 1 : this.calculateContainersCountBeforeGivenIndex(this.form.details, order_index) + index + 1) : '',
        isFirstRow ? index + 1 : '',
        isFirstRow ? order.date : '',
        isFirstRow ? (order.departure ? order.departure.name : '-') : '',
        isFirstRow ? (order.destination ? order.destination.name : '-') : '',
        isFirstRow ? (order.product ? order.product.name : '-') : '',
        isFirstRow ? (order.product ? `ГНГ ${order.product.hs_code} / ЕТСНГ ${order.product.etcng_code}` : '-') : '',
        isFirstRow ? container.number || '-' : '',
        code ? code.smgs || '-' : '-',
        code ? code.code_number.replace(/['"]/g, '') || '-' : '-',
        code ? code.territory || '-' : '-',
        isFirstRow ? (container ? container.type : '-') : '',
        isFirstRow ? `$ ${this.formatPrice(container.agreed_rate)}` : '',
        isFirstRow ? `$ ${this.formatPrice(container.additional_cost)}` : '',
        isFirstRow ? `$ ${parseFloat((container.total_agreed_rate || 0) + (container.additional_cost || 0)).toLocaleString('en-UK', {
          minimumFractionDigits: 1,
          maximumFractionDigits: 2
        })}` : ''
      ]);

      row.eachCell((cell) => {
        cell.alignment = {vertical: 'middle', horizontal: 'center'};
        cell.border = {
          top: {style: 'thin'},
          left: {style: 'thin'},
          bottom: {style: 'thin'},
          right: {style: 'thin'}
        };
      });

      if (isLastCodeRow) {
        const firstRow = row.number - (container.codes.length - 1);
        this.mergeCells(worksheet, firstRow, row.number, ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'L', 'M', 'N', 'O']);
      }
    },
    addWagonRow(worksheet, order, wagon, index, order_index, isFirstRow, code, isLastCodeRow) {
      const row = worksheet.addRow([
        isFirstRow ? (order_index === 0 ? index + 1 : this.calculateContainersCountBeforeGivenIndex(this.form.details, order_index) + index + 1) : '',
        isFirstRow ? index + 1 : '',
        isFirstRow ? order.date : '',
        isFirstRow ? (order.departure ? order.departure.name : '-') : '',
        isFirstRow ? (order.destination ? order.destination.name : '-') : '',
        isFirstRow ? (order.product ? order.product.name : '-') : '',
        isFirstRow ? (order.product ? `ГНГ ${order.product.hs_code} / ЕТСНГ ${order.product.etcng_code}` : '-') : '',
        isFirstRow ? wagon.actual_weight || '-' : '',
        isFirstRow ? wagon.number || '-' : '',
        code ? code.smgs || '-' : '-',
        code ? code.code_number.replace(/['"]/g, '') || '-' : '-',
        code ? code.territory || '-' : '-',
        isFirstRow ? (wagon ? wagon.wagon_type : '-') : '',
        isFirstRow ? `$ ${this.formatPrice(wagon.agreed_rate)}` : '',
        isFirstRow ? `$ ${this.formatPrice(wagon.additional_cost)}` : '',
        isFirstRow ? `$ ${parseFloat((wagon.total_agreed_rate || 0) + (wagon.additional_cost || 0)).toLocaleString('en-UK', {
          minimumFractionDigits: 1,
          maximumFractionDigits: 2
        })}` : ''
      ]);

      row.eachCell((cell) => {
        cell.alignment = {vertical: 'middle', horizontal: 'center'};
        cell.border = {
          top: {style: 'thin'},
          left: {style: 'thin'},
          bottom: {style: 'thin'},
          right: {style: 'thin'}
        };
      });

      if (isLastCodeRow) {
        const firstRow = row.number - (wagon.codes.length - 1);
        this.mergeCells(worksheet, firstRow, row.number, ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'L', 'M', 'N', 'O']);
      }
    },
    addWagonEmptyRow(worksheet, order, wagon, index, order_index, isFirstRow, code, isLastCodeRow) {
      const row = worksheet.addRow([
        isFirstRow ? (order_index === 0 ? index + 1 : this.calculateContainersCountBeforeGivenIndex(this.form.details, order_index) + index + 1) : '',
        isFirstRow ? index + 1 : '',
        isFirstRow ? order.date : '',
        isFirstRow ? (order.departure ? order.departure.name : '-') : '',
        isFirstRow ? (order.destination ? order.destination.name : '-') : '',
        isFirstRow ? wagon.actual_weight || '-' : '',
        isFirstRow ? wagon.number || '-' : '',
        code ? code.smgs || '-' : '-',
        code ? code.code_number.replace(/['"]/g, '') || '-' : '-',
        code ? code.territory || '-' : '-',
        isFirstRow ? (wagon ? wagon.wagon_type : '-') : '',
        isFirstRow ? `$ ${this.formatPrice(wagon.agreed_rate)}` : '',
        isFirstRow ? `$ ${this.formatPrice(wagon.additional_cost)}` : '',
        isFirstRow ? `$ ${parseFloat((wagon.total_agreed_rate || 0) + (wagon.additional_cost || 0)).toLocaleString('en-UK', {
          minimumFractionDigits: 1,
          maximumFractionDigits: 2
        })}` : ''
      ]);

      row.eachCell((cell) => {
        cell.alignment = {vertical: 'middle', horizontal: 'center'};
        cell.border = {
          top: {style: 'thin'},
          left: {style: 'thin'},
          bottom: {style: 'thin'},
          right: {style: 'thin'}
        };
      });

      if (isLastCodeRow) {
        const firstRow = row.number - (wagon.codes.length - 1);
        this.mergeCells(worksheet, firstRow, row.number, ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'L', 'M', 'N', 'O']);
      }
    },
    mergeCells(worksheet, startRow, endRow, columns) {
      columns.forEach((col) => {
        worksheet.mergeCells(`${col}${startRow}:${col}${endRow}`);
      });
    },
    formatPrice(price) {
      if (price) {
        return parseFloat(price).toLocaleString('en-UK', {
          minimumFractionDigits: 1,
          maximumFractionDigits: 2
        });
      }
      return 0;
    },
    calculateContainersCountBeforeGivenIndex(list, index) {
      if (index === 0) return 0;
      if (this.order_type === 'container_order') {
        return list.slice(0, index).reduce((acc, order) => acc + order.containers.length, 0);
      } else if (this.order_type === 'wagon_order') {
        return list.slice(0, index).reduce((acc, order) => acc + order.wagons.length, 0);
      } else if (this.order_type === 'wagon_empty_order') {
        return list.slice(0, index).reduce((acc, order) => acc + order.empty_wagons.length, 0);
      }
    },
    slugify(str) {
      try {
        str = str.replace(/^\s+|\s+$/g, '');
        str = str.toLowerCase();

        var from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;';
        var to = 'aaaaeeeeiiiioooouuuunc------';

        for (var i = 0, l = from.length; i < l; i++) {
          str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
        }

        str = str.replace(/[^a-z0-9 -]/g, '')
            .replace(/\s+/g, '-')
            .replace(/-+/g, '-');

        return str;
      } catch {
        return str
      }
    },

    priceToWord() {
      try {
        this.form.total_agreed_rate_in_words = numberToWordsRu.convert(parseFloat(this.totalAgreedRate), {
          currency: "usd"
        })
      } catch {
        this.form.total_agreed_rate_in_words = ''
      }
    }
  },
  watch: {
    "form.contract": {
      handler: function (newVal) {
        if (newVal) {
          this.getLotNumbersByCompanyIdAndContract();
        } else {
          this.clearLotNumber()
          this.clearOrders()
        }
      },
      deep: true
    },
    "form.lot_number": {
      handler: function (newVal) {
        if (newVal) {
          this.autoSelectOrdersByLotNumber();
        } else {
          this.form.orders = []
        }
      },
      deep: true
    },
    "allFieldsFilled": {
      handler: function (newVal) {
        if (newVal) {
          this.getAvrDetails();
        }
      },
      immediate: true
    },
    "form.orders": {
      handler: function () {
        if (this.allFieldsFilled) {
          this.getAvrDetails();
        }
      },
      deep: true
    },
    "totalAgreedRate": {
      handler: function () {
        this.priceToWord();
      },
      immediate: true,
      deep: true
    }
  },
  mounted() {
    if (this.$route.params.avr_id) {
      this.getAvrDetailsByAvrId()
    } else {
      this.setDefaultPerformer()
    }
  }
}
</script>

<template>
  <b-card>
    <b-row>
      <b-col sm="9">
        <div class="table-responsive">
          <table class="table table-bordered mb-0">
            <tbody>
            <tr class="align-middle">
              <th>Заказчик</th>
              <td>
                <p class="mb-0" v-if="form.customer">
                  <router-link v-if="form.customer.slug"
                               :to="{name: 'customer_profile', params: {slug: form.customer.slug}}">
                    {{ form.customer.label }},<br>
                  </router-link>
                  <template v-else>
                    {{ form.customer.label }},<br>
                  </template>
                  {{ form.customer.address }}
                </p>
                <p class="mb-0" v-else>-</p>
              </td>
              <td style="min-width: 180px" class="p-0">
                <SelectCompany class="border-0"
                               @on-select="onCustomerSelected($event, true)"
                               :current_company="form.customer ? {
                                 id: form.customer.value,
                                  name: form.customer.label,
                                  address: form.customer.address,
                                  director_name: form.customer.director_name || '',
                               } : null"
                />
              </td>
            </tr>
            <tr class="align-middle">
              <th>Исполнитель</th>
              <td>
                <p class="mb-0" v-if="form.performer">
                  {{ form.performer.label }},<br>
                  {{ form.performer.address }}
                </p>
                <p class="mb-0" v-else>-</p>
              </td>
              <td class="p-0">
                <SelectCompany class="border-0"
                               @on-select="onPerformerSelected"
                               :current_company="form.performer ? {
                                 id: form.performer.value,
                                  name: form.performer.label,
                                  address: form.performer.address
                               } : null"
                />
              </td>
            </tr>
            <tr class="align-middle">
              <th>Договор (контракт)</th>
              <td>
                <p class="mb-0" v-if="!contractSelect.has_loaded_contracts">-</p>
                <div v-else>
                  <p class="mb-0" v-if="form.contract">
                    {{ form.contract.label }}<br>
                  </p>
                  <p class="mb-0 text-danger" v-if="contractSelect.options.length === 0">
                    No contracts found!
                  </p>
                </div>
              </td>
              <td class="p-0">
                <Multiselect v-model="form.contract"
                             v-bind="contractSelect"
                             :disabled="!form.customer"
                             v-b-tooltip.hover
                             :title="!form.customer ? '🚫 Select a customer first' : 'Select a contract'"
                />
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </b-col>
      <b-col sm="3">
        <div class="d-flex flex-column justify-content-between h-100 gap-3">
          <div>
            <input v-model="form.inn" class="form-control border-soft-dark border-3" placeholder="ИИН/БИН">
          </div>
          <div>
            <Multiselect v-model="form.lot_number"
                         v-bind="lotNumberSelect" :disabled="!form.customer"
                         v-b-tooltip.hover :title="!form.customer ? '🚫 Select a customer first' : 'Select a lot number'"
            />
          </div>
          <div>
            <Multiselect v-model="form.orders"
                         v-bind="ordersSelect"
                         :disabled="!form.customer"
                         v-b-tooltip.hover :title="!form.customer ? '🚫 Select a customer first' : 'Select orders'"
            />
          </div>
        </div>
      </b-col>
    </b-row>
  </b-card>

  <b-card no-body>
    <b-card-body>
      <div class="mb-3">
        <b-row class="align-items-center">
          <b-col sm="9" class="text-center">
            <b>АКТ ВЫПОЛНЕННЫХ РАБОТ (ОКАЗАННЫХ УСЛУГ)</b>
          </b-col>
          <b-col sm="3">
            <div class="table-responsive">
              <table class="table table-bordered table-nowrap mb-0">
                <tbody>
                <tr class="text-center">
                  <td>Номер документа</td>
                  <td>Дата составления</td>
                </tr>
                <tr class="text-center align-middle py-0">
                  <td class="p-0 border border-success border-2">
                    <input v-model="form.avr_number" class="form-control border-0" type="text"
                           placeholder="Номер документа">
                  </td>
                  <td class="p-0 border border-success border-2">
                    <input v-model="form.date" class="form-control border-0" type="date">
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </b-col>
        </b-row>
      </div>
      <div>
        <div class="table-responsive">
          <table class="table table-bordered mb-0">
            <thead>
            <tr class="align-middle">
              <td rowspan="2">Номер по <br>порядку</td>
              <td rowspan="2">Наименование работ (услуг) (в разрезе их подвидов в <br>соответствии с технической
                спецификацией,
                заданием,<br>графиком выполнения работ (услуг) при их наличии)
              </td>
              <td rowspan="2">Дата выполнения <br>работ (оказания услуг)</td>
              <td rowspan="2">Сведения об отчете о научных исследованиях, <br>маркетинговых, консультационных и прочих
                <br>услугах
                (дата, номер, количество страниц) (при их наличии)
              </td>
              <td rowspan="2">Единица <br>измерения</td>
              <td colspan="3">Выполнено работ (оказано услуг)</td>
            </tr>
            <tr>
              <td>количество</td>
              <td>цена за единицу</td>
              <td>стоимость</td>
            </tr>
            <tr>
              <td v-for="index in 8" :key="`index_${index}`">{{ index }}</td>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>1</td>
              <td class="p-0 align-middle border border-success border-2">
                <input v-model="form.service_name" class="form-control border-0" placeholder="Type service name here">
              </td>
              <td>{{ form.date }}</td>
              <td></td>
              <td></td>
              <td>{{ totalQuantity }}</td>
              <td>$ {{
                  totalQuantity ? (totalAgreedRate / totalQuantity).toLocaleString('en-UK', {
                    minimumFractionDigits: 1,
                    maximumFractionDigits: 2
                  }) : 0
                }}
              </td>
              <td>$ {{
                  totalAgreedRate.toLocaleString('en-UK', {minimumFractionDigits: 1, maximumFractionDigits: 2})
                }}
              </td>
            </tr>
            <tr class="border-0 border-start">
              <td colspan="4" class="border-end"></td>
              <th class="border">Итого</th>
              <th class="border">{{ totalQuantity }}</th>
              <th class="border">x</th>
              <th class="border">$ {{
                  totalAgreedRate.toLocaleString('en-UK', {
                    minimumFractionDigits: 1,
                    maximumFractionDigits: 2
                  })
                }}
              </th>
            </tr>
            <tr class="border-0 border-start">
              <td colspan="6" class="border-end"></td>
              <td class="border">в том числе НДС</td>
              <th class="border"></th>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="mt-3">
        <div class="d-flex gap-4 flex-wrap mb-4">
          <label class="form-label">Всего наименований 1, на сумму</label>
          <b>$ {{
              totalAgreedRate.toLocaleString('en-UK', {
                minimumFractionDigits: 1,
                maximumFractionDigits: 2
              })
            }} США долл</b>
        </div>
        <div>

          <div class="row align-items-center gap-3 mb-4">
            <div class="col-12 col-lg-auto">
              <h6 class="mb-0 fw-bolder">Стоимость прописью:</h6>
            </div>
            <div class="col-12 col-lg-4">
              <input class="form-control border border-success border-2" v-model="form.total_agreed_rate_in_words"
                     placeholder="Стоимость прописью">
            </div>
          </div>

          <b-row class="align-items-start mb-4">
            <b-col md="5">
              <h6 class="mb-0 fw-bolder">Сведения об использовании запасов, полученных от заказчика</h6>
            </b-col>
            <b-col md="7" class="border-top border-dark text-center pt-1">
              <h6 class="mb-0 fw-bolder">наименование, количество, стоимость</h6>
            </b-col>
          </b-row>
          <b-row class="mb-5">
            <b-col>
              <h6 class="mb-0 fw-bolder lh-lg">
                Приложение: Перечень документации, в том числе отчет(ы) о маркетинговых, научных исследованиях,
                консультационных и прочих услугах (обязательны при его (их) наличии) на<br>
                <span class="text-muted me-2 fw-normal">_________________________</span> страниц <span
                  class="text-muted ms-2 fw-normal">_________________________</span>
              </h6>
            </b-col>
          </b-row>
          <div>
            <div class="table-responsive">
              <table class="table table-bordered">
                <thead>
                <tr class="align-middle text-center">
                  <th class="bg-light">Сдал (Исполнитель)</th>
                  <th>Глава представительства в Узбекистане</th>
                  <th>/</th>
                  <th style="min-width: 75px"></th>
                  <th>/</th>
                  <th>Сулейманов Д.</th>
                  <th class="bg-light">Принял (Заказчик)</th>
                  <th>Директор</th>
                  <th>/</th>
                  <th style="min-width: 75px"></th>
                  <th>/</th>
                  <th class="p-0 border border-success border-2" style="max-width: 175px">
                    <input v-model="form.director_name" class="form-control border-0" placeholder="Director name">
                    <div class="text-start px-2 pb-1" v-if="form.customer">
                      <h5 class="mb-0"
                          v-if="form.customer.director_name !== '' && this.form.director_name !== this.form.customer.director_name">
                      <span v-b-tooltip.hover
                            title="Director name provided in company cabinet. Click to use this director name"
                            @click="form.director_name = form.customer.director_name"
                            type="button" class="badge badge-gradient-info"
                      >{{ form.customer.director_name }}</span>
                      </h5>
                    </div>
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr class="align-middle text-center">
                  <th class="bg-light"></th>
                  <td>должность</td>
                  <th></th>
                  <td style="min-width: 75px">подпись</td>
                  <th></th>
                  <td>расшифровка подписи</td>
                  <th class="bg-light"></th>
                  <td>должность</td>
                  <th>/</th>
                  <td style="min-width: 75px">подпись</td>
                  <th></th>
                  <td>расшифровка подписи</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </b-card-body>
  </b-card>

  <div v-if="allFieldsFilled">

    <b-card-header class="mb-3">
      <b-card-title>
        <div class="d-flex align-items-утв flex-wrap gap-3">
          <span class="fw-bold lh-lg">
                  Приложение<br>
                к акту выполненных работ (оказанных услуг) №{{ form.contract ? form.contract.label : '-' }} между
                {{ form.customer ? form.customer.label : '-' }} и {{
              form.performer ? form.performer.label : '-'
            }}
        </span>
          <div class="d-flex align-items-center gap-4 flex-wrap">
            <div>
              <label class="form-label">с</label>
              <input v-model="form.start_date" class="form-control border-success border-2" type="date">
            </div>
            <div>
              <label class="form-label">по</label>
              <input v-model="form.expire_date" class="form-control border-success border-2" type="date">
            </div>
          </div>
        </div>
      </b-card-title>
    </b-card-header>

    <template v-if="!form.is_loading_details">
      <div class="mb-3">
        <b-button @click="exportToExcel()" variant="success">
          <i class="mdi mdi-file-excel mr-1 align-middle"></i>
          Download Excel
        </b-button>
      </div>

      <slot name="body" :form="form" :is_loading="form.is_loading_details">
      </slot>
    </template>

    <b-spinner v-else label="Loading ..."/>

    <form @submit.prevent="submitForm()">

      <b-card no-body>
        <b-card-body>
          <div>
            <label class="form-label">AVR Name</label>
            <input v-model="form.avr_name" class="form-control mb-3"
                   placeholder="Anything that helps you remember this avr">

            <div class="d-flex align-items-center gap-3">
              <template v-for="option in possible_avr_name_options" :key="`possible_option_${option}`">
                <h5 @click="this.form.avr_name = option.label" class="mb-0 cursor-pointer">
                  <span class="badge rounded-pill bg-soft-dark text-body">{{ option.label }}</span>
                </h5>
              </template>
            </div>

          </div>
        </b-card-body>
      </b-card>

      <div class="d-flex gap-3">
        <b-button :type="form.is_submitting ? 'button' : 'submit'"
                  :disabled="form.is_submitting || !form.start_date || !form.expire_date || form.avr_name.trim().length === 0"
                  variant="success" class="mb-4 w-100">
          Confirm & {{ this.$route.params.avr_id ? 'Update' : 'Submit' }} AVR
        </b-button>
        <b-button v-if="this.$route.params.avr_id" @click="avrDeleteConfirmation()" type="button"
                  :disabled="form.is_submitting"
                  variant="outline-danger" class="mb-4 w-100">
          Confirm & Delete AVR
        </b-button>
      </div>
    </form>

  </div>
</template>

<style scoped>

</style>