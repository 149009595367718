<script>
import PageHeader from "@/components/page-header.vue";
import AvrLayout from "@/views/pages/avr/components/AvrLayout.vue";

export default {
  name: "Create",
  components: {
    PageHeader,
    AvrLayout
  },
  data() {
    return {
      title: 'Create AVR - Container',
      items: [
        {
          text: "Home",
          href: "/",
        },
        {
          text: "AVR",
          href: "/avr",
        },
        {
          text: "Create AVR - Container",
          active: true,
        },
      ],
    }
  },
  methods: {
    calculateContainersCountBeforeGivenIndex(list, index) {
      if (index === 0) return 0
      return list.slice(0, index).reduce((acc, order) => acc + order.containers.length, 0)
    },
    formatPrice(price) {
      if (price) {
        return parseFloat(price).toLocaleString('en-UK', {
          minimumFractionDigits: 1,
          maximumFractionDigits: 2
        })
      }
      return 0
    },
    allTheSameSmgs(data) {
      if (!data) return false;
      if (data.length === 1 && data[0].smgs != null) {
        return true;
      }
      const nonNullSmgsValues = data.filter(item => item.smgs !== null).map(item => item.smgs);

      if (nonNullSmgsValues.length === 0) {
        return false;
      }

      const firstSmgs = nonNullSmgsValues[0];
      return nonNullSmgsValues.every(smgs => smgs === firstSmgs);
    },
  },
}
</script>

<template>
  <PageHeader
      :title="title"
      :items="items"
  />

  <AvrLayout order_type="container_order">
    <template v-slot:body="{ form }">
      <b-card v-for="(order, order_index) in form.details" :key="`order_${order_index}`">
        <div>
          <label class="form-label">Order number: {{ order.order_number }}</label>
          <div class="table-responsive">
            <table class="table table-bordered mb-0">
              <thead>
              <tr class="text-center align-middle">
                <th class="bg-light">№</th>
                <th>№</th>
                <th>Дата отправления</th>
                <th>Станция отправления</th>
                <th>Станция назначения</th>
                <th style="max-width: 200px">Груз</th>
                <th>ЕТСНГ/ГНГ</th>
                <th>Номер контейнера</th>
                <th>Номер накладной</th>
                <th>Код/подкод</th>
                <th>Территория</th>
                <th>Тип вагона/контейнера</th>
                <th>Ставка/ доллар</th>
                <th>Дополнительные расходы.</th>
                <th>Итого к оплате, доллар</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(container, index) in order.containers" :key="`container_${container}`"
                  class="align-middle text-center"
                  :class="{
              'bg-soft-danger': !allTheSameSmgs(container.codes)
                }"
              >
                <th class="bg-light">{{
                    order_index === 0 ? index + 1 : calculateContainersCountBeforeGivenIndex(form.details, order_index) + index + 1
                  }}
                </th>
                <th>{{ index + 1 }}</th>
                <td>{{ order.date }}</td>
                <td>{{ order.departure ? order.departure.name : '-' }}</td>
                <td>{{ order.destination ? order.destination.name : '-' }}</td>
                <td style="max-width: 200px">{{ order.product ? order.product.name : '-' }}</td>
                <td>
                  <p v-if="order.product" class="mb-0">
                    ГНГ {{ order.product.hs_code }} /
                    ЕТСНГ{{ order.product.etcng_code }}
                  </p>
                </td>
                <td>{{ container.number || '-' }}</td>
                <td class="p-0">
                  <b-list-group flush class="pb-0">
                    <b-list-group-item v-for="smgs in (container.codes || [])" :key="`smgs_number_${smgs}`"
                                       class="py-1 align-middle">
                      {{ smgs.smgs || '-' }}
                    </b-list-group-item>
                  </b-list-group>
                </td>
                <td class="p-0">
                  <b-list-group flush class="pb-0">
                    <b-list-group-item v-for="code in (container.codes || [])" :key="`smgs_code_${code}`"
                                       class="py-1 align-middle">
                      {{ code.code_number || '-' }}
                    </b-list-group-item>
                  </b-list-group>
                </td>
                <td class="p-0">
                  <b-list-group flush class="pb-0">
                    <b-list-group-item v-for="code in (container.codes || [])" :key="`smgs_territory_${code}`"
                                       class="py-1 align-middle">
                      {{ code.territory || '-' }}
                    </b-list-group-item>
                  </b-list-group>
                </td>
                <td>{{ container ? container.type : '-' }}</td>
                <td>$ {{ formatPrice(container.agreed_rate) }}</td>
                <td>$ {{ formatPrice(container.additional_cost) }}</td>
                <td>$ {{
                    container
                        ? parseFloat((container.total_agreed_rate || 0) + (container.additional_cost || 0)).toLocaleString('en-UK', {
                          minimumFractionDigits: 1,
                          maximumFractionDigits: 2
                        })
                        : 0
                  }}
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </b-card>
    </template>
  </AvrLayout>
</template>

<style scoped>

</style>